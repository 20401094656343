<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `Allemannia | ${content}` : `Allemannia` }}</template>
  </metainfo>
  <HeaderNav @mouseup="handleMouseUp"></HeaderNav>
  <div 
  @mouseup="handleMouseUp"
  @touchend="handleMouseUp">
    <div class="relative h-screen/2">
      <img style="top:-15%; transform: scale(0.35,0.35);" class="absolute h-full w-full z-20" src="@/assets/img/kai_wappen.svg">
      <div style="left: 50%;" class="absolute inset-y-1/2 transform -translate-x-1/2 -translate-y-1/2 font-voll text-wheat text-6xl z-10 align-top">Allemannia</div>
      <img style="filter: sepia(40%) brightness(55%) saturate(2);" class="object-cover object-center w-full h-full" 

src="@/assets/img/titel1.jpg"
srcset="@/assets/img/titel1.jpg,
@/assets/img/titel1_1400.jpg 1400w,
@/assets/img/titel1_800.jpg 800w">
       <div style="left: 50%; top:75%;" class="absolute inset-y-1/2 transform -translate-x-1/2 -translate-y-1/2 font-voll text-wheat text-2xl z-10 align-top whitespace-nowrap">~ Einer für Alle, Alle für Einen ~</div>
    </div>
    <router-view/>
  </div>
<footer class="min-h-8 text-app-menu-text bg-app-menu flex justify-between">
  <div class="w-20"></div><!-- Balance div left-->
  <div class="text-center p-3 text-xs  font-voll">
    <span>Impressum:</span>
        Burschenschaft Allemannia zu Heidelberg, 
        Karlstraße 10, 
        69117 Heidelberg, 
        bo<span style="display: none;">ckfu</span>x@allemannia.de
  </div>
  <div class="w-20 pr-2 flex items-center justify-end">
    <a class="flex pr-1" href="https://ahnas01.myqnapcloud.com:8081/Datenabruf/index.php" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
        <path fill="white" d="M7.5 6.8a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z
          M 7 20.105 9.3 20.105 16.6 13.38 15 12.54 
          M3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0"></path>
      </svg>
    </a>
    <a class="flex pr-1" href="https://www.instagram.com/Allemannen1856" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 132 132">
          <!--<defs><linearGradient id="b"><stop offset="0" stop-color="#3771c8"></stop><stop stop-color="#3771c8" offset=".128"></stop><stop offset="1" stop-color="#60f" stop-opacity="0"></stop></linearGradient><linearGradient id="a"><stop offset="0" stop-color="#fd5"></stop><stop offset=".1" stop-color="#fd5"></stop><stop offset=".5" stop-color="#ff543e"></stop><stop offset="1" stop-color="#c837ab"></stop></linearGradient><radialGradient id="c" cx="158.429" cy="578.088" r="65" xlink:href="#a" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)" fx="158.429" fy="578.088"></radialGradient><radialGradient id="d" cx="147.694" cy="473.455" r="65" xlink:href="#b" gradientUnits="userSpaceOnUse" gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)" fx="147.694" fy="473.455"></radialGradient></defs>
          -->
          <path fill="white" d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"></path>
        </svg>      
    </a>
    <a href="https://www.facebook.com/AllemanniaHeidelberg" target="_blank" class="flex">
      <svg class="w-6 h-6 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path>
      </svg>
    </a>     
  </div>
</footer>
</template>


<script>
// @ is an alias to /src
import HeaderNav from '@/components/HeaderNav.vue'
import emitter from '@/eventBus'
import { useMeta } from 'vue-meta'

export default {
  name: 'app',
  components: {
   HeaderNav
  },
 data() {
    return {

    };
  },
  methods:{
    handleMouseUp: function(event){
      emitter.emit('mouseup_global',event);
    }
  },
  // created(){
  //    document.title = "Allemannia1"
  // },
  // metaInfo: {
  //   title: 'Allemannia2',
  //   //titleTemplate: '%s - Yay!',
  //   htmlAttrs: {
  //     lang: 'de'
  //     //amp: true
  //   }
  // },
  setup () {
    useMeta({
      // title: 'Allemannia',
      htmlAttrs: { lang: 'de', amp: true }
    })
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');
</style>
