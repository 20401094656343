<template>
<div class="accordion ">
  <div
    class="accordion-item text-black"
    v-for="content, i in contents" :key="content"
    v-bind:class="{ 'accordion-active': content.active }"
  >
    <div class="accordion-header">
      <div class="accordion-header-link" v-on:click="expand(event, i)">
        <div class="accordion-header-div text-maroon font-voll">{{ content.name }}</div>
        <div class="accordion-header-div">
          <div class="accordion-caret"></div>
        </div>
      </div>
    </div>
    <div class="accordion-body" v-bind:ref="'accordion-body-' + i">
      <div class="accordion-content">
        <div class="flex justify-center items-center">
        <img :src="img_path(content.image)"/>
        </div>
        <p class="text-left py-2"> {{content.birth}} </p>
        <p class="text-left py-2"> {{content.death}} </p>
        <p class="text-left py-2"> {{content.position}} </p>
        <p class="text-left py-2"> {{content.internal_position}} </p>
        <p class="text-left" v-for="par in content.content" :key="par">
          <span v-if="par.type === 'none'"> {{par.text}} </span>
            <span v-if="par.type === 'list'"> 
              <br>
                <ul class="list-disc list-inside">
                  <li v-for="item in par.list" :key="item">{{item.text}}</li>
                </ul>
              <br>
            </span>
            <span class="font-bold" v-if="par.type === 'fat'"><br> {{par.fattext}} </span> <span v-if="par.type === 'fat'"> {{par.nonfattext}} </span> 
             <a :href="par.text" v-if="par.type === 'link'"> {{par.text}} </a>
        </p>
      </div>
    </div>
  </div>
</div>
</template>
<!-- 
<span v-for="sp in par" :key="sp"><b class="font-bold" v-if="sp.type === 'fat'">{{sp.content}}&nbsp;</b>
<span v-else>{{sp.content}}</span></span> -->

<style lang="scss">
  $base-color: #727272;
  $base-width:  100%;
  $base-time: 200ms;

  // $app-background-color: #82dfff;
  // $app-padding: 2rem;

  $accordion-background-color: #efefef;
  $accordion-max-width: $base-width;
  $accordion-padding: 2rem 1rem;
  $accordion-border-radius: 0.5rem;
  $accordion-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);

  $accordion-header-font-size: 1rem;
  $accordion-header-height: 1.5rem;
  $accordion-header-padding: 1rem 1rem 1rem 1rem;
  $accordion-header-border-radius: 0.6rem;
  $accordion-header-transition: background-color $base-time ease-in-out;

  $accordion-header-hover-background-color: var(--color-bg-base);

  $accordion-caret-color: $base-color;
  $accordion-caret-background-image: linear-gradient(
    to top right,
    transparent 50%,
    $accordion-caret-color 50%
  );
  $accordion-caret-width: 0.5rem;
  $accordion-caret-height: 0.5rem;
  $accordion-caret-transform: rotate(-45deg);
  $accordion-caret-transforming: scale(1.5) rotate(45deg);
  $accordion-caret-active-transform: rotate(135deg);

  $accordion-body-content-padding: 1rem;


  // html, body, #app {
  //   color: $base-color;
  //   font-family: Montserrat, sans-serif;
  //   font-size: 14px;
  //   width: 100%;
  //   height: 100%;
  //   padding: 0;
  //   margin: 0;
  // }

  // #app,
  // .accordion,
  // .accordion-item,
  // .accordion-header,
  // .accordion-body {
  //   font-family: inherit;
  // }

  .accordion {
    background-color: $accordion-background-color;
    // width: 100%;
    max-height: 100%;
    max-width: $accordion-max-width;
    padding: $accordion-padding;
    margin: auto;
    box-sizing: border-box;
    overflow: auto;
    box-shadow: $accordion-box-shadow;
  }

  .accordion-item.accordion-active {
    background-color: var(--color-bg-base);
    .accordion-caret {
      animation: accordion-is-active $base-time linear forwards;
    }
  }

  .accordion-header {
    color: inherit;
    font-size: $accordion-header-font-size;
    font-weight: bold;
    position: relative;
    a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      border-radius: $accordion-header-border-radius;
      transition: $accordion-header-transition;
      &:not(.accordion-active):hover {
        background-color: $accordion-header-hover-background-color;
      }
    }
  }

   .accordion-header-link {
      color: inherit;
      text-decoration: none;
      // width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      // box-sizing: border-box;
      border-radius: $accordion-header-border-radius;
      transition: $accordion-header-transition;
      &:not(.accordion-active):hover {
        background-color: $accordion-header-hover-background-color;
      }
    }

  .accordion-header-div {
    padding: $accordion-header-padding;
    &:last-child {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .accordion-caret {
    background-image: $accordion-caret-background-image;
    width: $accordion-caret-width;
    height: $accordion-caret-height;
    transform: $accordion-caret-transform;
    animation: accordion-is-inactive $base-time linear forwards;
  }

  .accordion-body {
    height: 0;
    overflow: hidden;
  }

  .accordion-content {
    padding: $accordion-body-content-padding;
  }

  @keyframes accordion-is-inactive {
    0%   { transform: $accordion-caret-transform; }
    50%  { transform: $accordion-caret-transforming; }
    100% { transform: $accordion-caret-active-transform; }
  }

  @keyframes accordion-is-active {
    0%   { transform: $accordion-caret-active-transform; }
    50%  { transform: $accordion-caret-transforming; }
    100% { transform: $accordion-caret-transform; }
  }

</style>

<script>
import { gsap } from 'gsap'

export default {
  name: "AccordionComponent",
  data: function() {
    for (let i = 0; i < this.$famous.length; i++){
      this.$famous[i]["active"] = false;
    }
    return {"contents" : this.$famous};
  },
  methods: {
    img_path : function(fn) {
      console.log('../assets/img/'+fn)
      return require('../assets/img/'+fn);
    },
    expand: function(e, i) {

      // e.preventDefault();

      let el = this.$refs['accordion-body-' + i][0];

      if (this.contents[i].active === false) {
          this.contents[i].active = true;

          gsap.to(el, 0.5, {
          height: el.scrollHeight,
          ease: "power2.in",
          duration: 2.5
        });
      } else {
          this.contents[i].active = false;

          gsap.to(el, 0.5, {
          height: 0,
          ease: "power2.out"
        });
      }
    },
  }
};
</script>
